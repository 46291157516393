import "./DeviceLicenseTab.css";
import {MC_Device} from "../../../common/iot/MC_Device";
import {
    Alert,
    Button, CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Snackbar
} from "@mui/material";
import React from "react";
import {MC_Constants} from "../../../common/MC_Constants";
import {MC_Device_Manual_License} from "../../../common/iot/MC_Device_Manual_License";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {MC_Backend} from "../../../common/MC_Backend";
import {SimpleSubscription} from "../../../common/iot/simple_stripe_types";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import SubscriptionTile from "../../../common/ui/billing/SubscriptionTile";


export interface DeviceLicenseTabProps {
    device: MC_Device;
}

function DeviceLicenseTab(props: DeviceLicenseTabProps) {
    const device: MC_Device = props.device;
    const sub: SimpleSubscription | null = device.simple_sub;
    const usingManualLicense: boolean = device.useManualLicense;
    const existingManualLicense: MC_Device_Manual_License | null = device.manualLicense;
    const initialManualStartDate: Date | null = (existingManualLicense != null) ? existingManualLicense.start : null;
    const initialManualMonths: number | null = (existingManualLicense != null) ? existingManualLicense.months : null;
    const [manualStartDate, setManualStartDate] = React.useState<Date | null>(initialManualStartDate);
    const [manualMonths, setManualMonths] = React.useState<number | null>(initialManualMonths);
    const [useOpInProgress, setUseOpInProgress] = React.useState<boolean>(false);
    const [updateOpInProgress, setUpdateOpInProgress] = React.useState<boolean>(false);
    const [removeOpInProgress, setRemoveOpInProgress] = React.useState<boolean>(false);
    const [errMsg, setErrMsg] = React.useState<string | null>(null);
    const [errSnackOpen, setErrSnackOpen] = React.useState<boolean>(false);
    const [successSnackOpen, setSuccessSnackOpen] = React.useState<boolean>(false);

    // Helper functions & data
    const dayJSStartDate: Dayjs = dayjs(manualStartDate);
    const manualEndDate: Date | null = MC_Device_Manual_License.calculateEndDate(manualStartDate, manualMonths);
    const manualExpirationStr: string =  MC_Constants.getReadableDateStr(manualEndDate, true);
    const updateSettingOpFn = () => {
        setUseOpInProgress(true);
        MC_Backend.getInstance().updateDeviceUseManualLicense(device.id, !device.useManualLicense).then(() => {
            setUseOpInProgress(false);
            setSuccessSnackOpen(true);
        }).catch((err) => {
            setUseOpInProgress(false);
            setErrMsg("Error changing license mode: " + err);
            setErrSnackOpen(true);
        });
    };
    const removeLicenseOpFn = () => {
        setRemoveOpInProgress(true);
        MC_Backend.getInstance().updateDeviceManualLicense(device.id, null).then(() => {
            setManualStartDate(null);
            setManualMonths(null);
            setRemoveOpInProgress(false);
            setSuccessSnackOpen(true);
        }).catch((err) => {
            setRemoveOpInProgress(false);
            setErrMsg("Error removing license: " + err);
            setErrSnackOpen(true);
        });
    };
    const updateLicenseOpFn = () => {
        // Validate data first
        if (manualStartDate == null) {
            setErrMsg("Invalid start date.");
            setErrSnackOpen(true);
            return;
        } else if (manualMonths == null || isNaN(manualMonths)) {
            setErrMsg("Invalid number of service months.");
            setErrSnackOpen(true);
            return;
        } else if (manualMonths < 3 || manualMonths > 36) {
            setErrMsg("Service months must between 3-36.");
            setErrSnackOpen(true);
            return;
        }
        const newLicense = MC_Device_Manual_License.fromValues(manualStartDate, manualMonths);
        setUpdateOpInProgress(true);
        MC_Backend.getInstance().updateDeviceManualLicense(device.id, newLicense).then(() => {
            setUpdateOpInProgress(false);
            setSuccessSnackOpen(true);
        }).catch((err) => {
            setUpdateOpInProgress(false);
            setErrMsg("Error updating license: " + err);
            setErrSnackOpen(true);
        });
    };
    const handleStartDateChangeFn = (x: unknown) => {
        let ts: number | null = parseInt(x + "");
        //console.log("sd change: " + ts);
        if (ts == null || isNaN(ts)) {
            setManualStartDate(null);
        } else {
            const parsedDate: Date = new Date(ts);
            parsedDate.setHours(0,0,0,0);
            setManualStartDate(parsedDate);
        }
    };
    const handleMonthsChangeFn = (ev: SelectChangeEvent) => {
        const newValueAny: any = ev.target.value;
        let newValue: number | null = null;
        if (newValueAny == null) {
            // undefined or null
            newValue = null;
        } else if (typeof(newValueAny) === "string") {
            // string
            if ((newValueAny as string).length > 0) {
                const parsedInt: number = parseInt(newValueAny);
                if (!isNaN(parsedInt)) {
                    newValue = parsedInt;
                }
            }
        } else {
            // number
            newValue = newValueAny as number;
        }
        setManualMonths(newValue);
    }
    const spinnerSize: number = 32;
    // UI
    return (<div className={"dlt-root"}>

        {/* Snack bars */}
        <Snackbar
            autoHideDuration={3000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={errSnackOpen} onClose={() => setErrSnackOpen(false)}>
            <Alert severity="error" sx={{ width: '100%' }}>
                {(errMsg != null) ? errMsg : "Unknown error"}
            </Alert>
        </Snackbar>
        <Snackbar
            autoHideDuration={3000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={successSnackOpen} onClose={() => setSuccessSnackOpen(false)}>
            <Alert severity="success" sx={{ width: '100%' }}>
                Updated License.
            </Alert>
        </Snackbar>

        {/* Basic info */}
        <Paper className={"content-start"} elevation={3}>
            <div className={"license-note-div"}>
                <span className={"license-note"}>
                    Subscription licenses can be purchased in the customer portal.
                </span>
                <div className={"license-note-spacer"}></div>
                <span className={"license-note"}>
                    Alternatively, you can provide an explicit license expiration date instead
                    <br/>
                    This approach is used for payments collected outside of the platform.
                </span>
            </div>
            <br/>
            <div className={"center-row"}>
                <Button variant={"contained"} color={(usingManualLicense) ? "primary" : "warning"}
                        disabled={useOpInProgress} onClick={updateSettingOpFn}>
                    <b>{(usingManualLicense) ? "Use subscription licensing" : "Use Explicit License Term"}</b>
                </Button>
            </div>
            <span className={"small-note"}>This setting only applies to this device.</span>
        </Paper>

        <br/>

        {/* Manual license */}
        {(usingManualLicense) &&
            <Paper className={"content-start"} elevation={3}>
                <span className={"card-title"}>Manually Provided License</span>
                <div className={"edit-license-div"}>
                    {/* Start date */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={"Start Date"}
                            value={dayJSStartDate}
                            onChange={(newDateVal) => handleStartDateChangeFn(newDateVal)}
                        />
                    </LocalizationProvider>

                    {/* Number of months */}
                    <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="months_label">Months of Service</InputLabel>
                        <Select
                            labelId="months_label"
                            value={(manualMonths != null) ? "" + manualMonths : ""}
                            onChange={handleMonthsChangeFn}
                        >
                            <MenuItem value={""}><em>Unselected</em></MenuItem>
                            <MenuItem value={"3"}>3 Months</MenuItem>
                            <MenuItem value={"6"}>6 Months</MenuItem>
                            <MenuItem value={"12"}>1 Year</MenuItem>
                            <MenuItem value={"24"}>2 Years</MenuItem>
                            <MenuItem value={"36"}>3 Years</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Expiration str */}
                    <p className={"note"}>This license will expire on {manualExpirationStr}</p>

                    {/* Update/create section */}
                    <div className={"edit-license-section"}>
                        {(!updateOpInProgress) &&
                            <Button className={"manual-action-btn"} variant={"contained"} color={"primary"}
                                    disabled={updateOpInProgress || removeOpInProgress} onClick={updateLicenseOpFn}>
                                <b>{(existingManualLicense == null) ? "Create License" : "Update License"}</b>
                            </Button>
                        }
                        {(updateOpInProgress) &&
                            <CircularProgress color={"primary"} size={spinnerSize}/>
                        }
                    </div>

                    {/* Remove section */}
                    {(existingManualLicense != null) &&
                        <div className={"edit-license-section"}>
                            {(!removeOpInProgress) &&
                                <Button className={"manual-action-btn"} variant={"contained"} color={"warning"}
                                        disabled={updateOpInProgress || removeOpInProgress} onClick={removeLicenseOpFn}>
                                    <b>{"Remove License"}</b>
                                </Button>
                            }
                            {(removeOpInProgress) &&
                                <CircularProgress color={"warning"} size={spinnerSize}/>
                            }
                        </div>
                    }

                </div>
            </Paper>
        }

        {/* Automatic license */}
        {(!usingManualLicense) &&
            <React.Fragment>
                {/* No sub */}
                {(sub == null) &&
                    <p className={"error-note"}>This device does not currently have a subscription.</p>
                }
                {/* Sub tile */}
                {(sub != null) &&
                    <SubscriptionTile sub={sub}/>
                }
            </React.Fragment>
        }

    </div>);
}

export default DeviceLicenseTab;