import {MC_Constants} from "../MC_Constants";
import {MC_Device_Manual_License} from "./MC_Device_Manual_License";
import {SimpleInvoice, SimpleSubscription, SimpleSubscriptionStatus} from "./simple_stripe_types";
import {MC_Device_License_Stats} from "./MC_Device_License_Stats";

export class MC_Device {

    public static FIELD_ID: string = "id";
    public static FIELD_JAS_ID: string = "jas_id";
    public static FIELD_CLAIM_CODE: string = "claim_code";
    public static FIELD_DEMO: string = "demo";
    public static FIELD_SOLD: string = "sold";
    public static FIELD_USE_MANUAL_LICENSE: string = "use_manual_license";
    public static FIELD_MANUAL_LICENSE: string = "manual_license";
    public static FIELD_ANNUAL_PRICE: string = "annual_price";
    public static FIELD_PRICE_ID: string = "price_id";
    public static FIELD_SIMPLE_SUBSCRIPTION: string = "simple_subscription";
    public static FIELD_LICENSED: string = "licensed";
    public static FIELD_CREATED: string = "created";
    public static FIELD_MODIFIED: string = "modified";
    public static FIELD_TYPE: string = "type";
    public static FIELD_FULL_TYPE: string = "full_type";
    public static FIELD_NAME: string = "name";
    public static FIELD_NOTES: string = "notes";
    public static FIELD_SETTINGS: string = "settings";
    public static FIELD_INTERVAL: string = "interval";
    public static FIELD_LAST_POST: string = "last_post";
    public static FIELD_ORGANIZATION_IDS: string = "organization_ids";
    public static FIELD_ADMIN_SETTINGS: string = "admin_settings";
    public static FIELD_ADMIN_NOTES: string = "admin_notes";
    public static FIELD_RECENT: string = "recent";

    public id: string;
    public jas_id: string;
    public readableJASID: string; // Calculated
    public claim_code: string;
    public demo: boolean;
    public sold: boolean;
    public useManualLicense: boolean;
    public manualLicense: MC_Device_Manual_License | null;
    public annualPrice: number;
    public priceID: string | null;
    public simple_sub: SimpleSubscription | null;
    public licensed: boolean;
    public created: Date | null;
    public modified: Date | null;
    public type: string;
    public fullType: string;
    public name: string;
    public notes: string;
    public settings: any;
    public interval: number;
    public last_post: Date | null;
    public organizationIDs: string[];
    public adminSettings: any;
    public adminNotes: string;
    public recent: any;

    public constructor(data: any) {
        let createdDate: Date | null = MC_Constants.parseDate(data[MC_Device.FIELD_CREATED] as string);
        let modifiedDate: Date | null = MC_Constants.parseDate(data[MC_Device.FIELD_MODIFIED] as string);
        let lastPostDate: Date | null = MC_Constants.parseDate(data[MC_Device.FIELD_LAST_POST] as string);
        this.id = data[MC_Device.FIELD_ID] as string;
        this.jas_id = data[MC_Device.FIELD_JAS_ID] as string
        this.readableJASID = MC_Constants.parseReadableJASID(this.jas_id, this.id);
        this.claim_code = (data[MC_Device.FIELD_CLAIM_CODE])
            ? data[MC_Device.FIELD_CLAIM_CODE] as string
            : "N/A"
        ;
        this.demo = data[MC_Device.FIELD_DEMO] as boolean;
        this.sold = data[MC_Device.FIELD_SOLD] as boolean;
        this.useManualLicense = MC_Constants.parseNullableField<boolean>(
            data, MC_Device.FIELD_USE_MANUAL_LICENSE, false
        );
        const manualLicenseAny: any | null = data[MC_Device.FIELD_MANUAL_LICENSE];
        this.manualLicense = (manualLicenseAny != null)
            ? new MC_Device_Manual_License(manualLicenseAny)
            : null
        ;
        this.annualPrice = MC_Constants.parseNullableField<number>(
            data, MC_Device.FIELD_ANNUAL_PRICE, 0
        );
        this.priceID = MC_Constants.parseNullableField<string | null>(
            data,
            MC_Device.FIELD_PRICE_ID,
            null
        );
        this.simple_sub = (data[MC_Device.FIELD_SIMPLE_SUBSCRIPTION] != null)
            ? SimpleSubscription.fromData(data[MC_Device.FIELD_SIMPLE_SUBSCRIPTION])
            : null
        ;
        this.licensed = MC_Constants.parseNullableField<boolean>(data, MC_Device.FIELD_LICENSED, false);
        this.created = createdDate;
        this.modified = modifiedDate;
        this.type = data[MC_Device.FIELD_TYPE] as string;
        this.fullType = data[MC_Device.FIELD_FULL_TYPE] as string;
        this.name = data[MC_Device.FIELD_NAME] as string;
        this.notes = data[MC_Device.FIELD_NOTES] as string;
        this.settings = MC_Constants.parseNullableField<any>(
            data, MC_Device.FIELD_SETTINGS, {}
        );
        this.interval = data[MC_Device.FIELD_INTERVAL] as number;
        this.last_post = lastPostDate;
        this.organizationIDs = data[MC_Device.FIELD_ORGANIZATION_IDS] as string[];
        this.adminSettings = data[MC_Device.FIELD_ADMIN_SETTINGS];
        this.adminNotes = data[MC_Device.FIELD_ADMIN_NOTES] as string;
        this.recent = data[MC_Device.FIELD_RECENT];
    }

    public isOnline(fromRefreshTS: Date): boolean {
        if (this.last_post != null) {
            const refreshTS: number = fromRefreshTS.getTime();
            const lastPostTS: number = this.last_post.getTime();
            // Online ff this device was online after refresh (refreshed individually)
            if (lastPostTS >= refreshTS) {
                return true;
            }
            // Must be online within 2 intervals from refresh time
            const minPassed = (refreshTS - lastPostTS) / 60000;
            if (minPassed <= (2 * this.interval)) {
                return true;
            }
        }
        return false;
    }

    // Returns [color, text, hoverText]
    public getLicenseOverviewStats(): MC_Device_License_Stats {
        if (this.useManualLicense) {
            return this.getManualLicenseStats();
        }
        return this.getAutoLicenseStats();
    }

    // Get manual license status
    private getManualLicenseStats(): MC_Device_License_Stats {
        // Props that may be overridden
        let sortNum: number | null = null;
        let color: "green"|"orange"|"red" = "red";
        let text: string = "NONE";
        let hoverText: string = "Requires manual license";
        // Process license
        if (this.manualLicense != null) {
            const end: Date | null = MC_Device_Manual_License.calculateEndDate(
                this.manualLicense.start, this.manualLicense.months
            );
            if (end != null) {
                const endMs: number = end.getTime();
                const nowMs: number = new Date().getTime();
                const diffMs: number = (endMs - nowMs);
                // See how many days are left
                const daysLeft: number = Math.floor(diffMs / (24 * 60 * 60 * 1000));
                sortNum = daysLeft; // Set sort number as days left
                if (daysLeft < 0) {
                    // License is expired
                    const absDL: number = Math.abs(daysLeft); // Days left absolute value
                    color = "red";
                    text = "EXPIRED";
                    hoverText = "Expired " + absDL + " day(s) ago";
                } else if (daysLeft <= 30) {
                    // Expires soon
                    color = "orange";
                    text = daysLeft + " DAY(S) LEFT";
                    hoverText = "Expires in " + daysLeft + " day(s)";
                } else {
                    // License good for a while
                    color = "green";
                    text = "ACTIVE";
                    hoverText = "Expires in " + daysLeft + " days";
                }
            }
        }
        // Done
        return {
            sortNumber: sortNum,
            color: color,
            title: text,
            hoverText: hoverText
        };
    }

    // Get automatic license status
    private getAutoLicenseStats(): MC_Device_License_Stats {
        // Props that may be overridden
        let sortNum: number | null = null;
        let color: "green"|"orange"|"red" = "red";
        let text: string = "NONE";
        let hoverText: string = "Waiting for subscription";
        // Process license
        if (this.simple_sub != null) {
            const sub: SimpleSubscription = this.simple_sub;
            const status: SimpleSubscriptionStatus = sub.status;
            const invoice: SimpleInvoice | null = sub.latestInvoice;
            const [usageStart, usageEnd] = MC_Constants.getInvoiceUsagePeriod(invoice);
            // Determine how many days are left in the latest invoice usage period
            const nowSec: number = ((new Date().getTime()) / 1000);
            let daysLeft: number = 0; // May be overwritten
            if (usageEnd != null) {
                const secondsLeft = (usageEnd - nowSec);
                daysLeft = Math.floor(secondsLeft / (24 * 60 * 60));
            }
            // Handle subscription state
            if (status === "incomplete") {
                // Subscription waiting for first valid payment
                sortNum = 0;
                color = "red";
                text = "INCOMPLETE";
                hoverText = "Waiting for valid payment";
            } else if (status === "trialing") {
                // Determine if the latest invoice represents a real free trial, or "short" trial
                const isShortTrial: boolean = MC_Constants.isInvoiceFromShortTrial(invoice);
                if (isShortTrial) {
                    // Subscription in "short" trial, and will be charged shortly
                    sortNum = 0;
                    color = "green";
                    text = "ACTIVE";
                    hoverText = "Waiting for initial payment";
                } else {
                    // Subscription in free trial
                    //-
                    // Set sort number
                    sortNum = daysLeft;
                    // Check how many days are left in the free trial
                    if (daysLeft <= 7) {
                        // Trial ending soon
                        color = "orange";
                        text = "FREE TRIAL";
                        hoverText = "Trial ending in " + daysLeft + " day(s)";
                    } else {
                        // Trial has a lot of time left
                        color = "green";
                        text = "FREE TRIAL";
                        hoverText = "Trial ending in " + daysLeft + " day(s)";
                    }
                }
            } else if (status === "active") {
                // Subscription active
                //-
                // Set sort number
                sortNum = daysLeft;
                // Check if the subscription will renew or cancel
                const cancelRenewStr: string = (sub.cancelAtPeriodEnd) ? "Cancels" : "Renews";
                // Determine the state of the license
                if (daysLeft <= 30) {
                    // Ending soon
                    color = "orange";
                    text = daysLeft + " DAY(S) LEFT";
                    hoverText = cancelRenewStr + " in " + daysLeft + " day(s)";
                } else {
                    // Lot of time left
                    color = "green";
                    text = "ACTIVE";
                    hoverText = cancelRenewStr + " in " + daysLeft + " day(s)";
                }
            } else if (status === "past_due" || status === "unpaid") {
                // Subscription overdue
                //-
                // Check how many days it's overdue
                let daysOverdueNegative: number = -1; // Overwritten
                if (invoice != null) {
                    // Manual sub invoices have a specific due date.
                    // For automatic subs, we just refer to the start of the usage period.
                    let invDueSeconds: number | null = (sub.collectionMethod === "send_invoice")
                        ? invoice.dueDate
                        : usageStart
                    ;
                    if (invDueSeconds != null) {
                        // Calculate the days overdue
                        const secondsOverdueNegative: number = (invDueSeconds - nowSec); // SHOULD be negative
                        daysOverdueNegative = Math.floor(secondsOverdueNegative / (24 * 60 * 60)); // SHOULD be negative
                    }
                }
                // Set sort number
                sortNum = daysOverdueNegative;
                // Indicate how many days overdue the payment is
                color = "red";
                text = "OVERDUE";
                hoverText = "Payment overdue by " + Math.abs(daysOverdueNegative) + " day(s)";
            } else {
                // This status is unexpected:
                // "incomplete_expired" | "paused" | "canceled"
                sortNum = -999;
                color = "red";
                text = "UNSUPPORTED";
                hoverText = "Unexpected status: " + status;
            }
        }
        // Done
        return {
            sortNumber: sortNum,
            color: color,
            title: text,
            hoverText: hoverText
        };
    }

    // Optional custom settings tab, can be overridden
    public getSettingsUI(): JSX.Element | null {
        return null;
    }

}

