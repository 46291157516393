import "./SubscriptionTile.css";
import {MC_Device} from "../../iot/MC_Device";
import {
    SimpleInvoice, SimpleInvoiceStatus,
    SimplePaymentIntentStatus,
    SimpleSubscription,
    SimpleSubscriptionStatus
} from "../../iot/simple_stripe_types";
import {Divider, Paper} from "@mui/material";
import {DevicesContext, OrganizationsContext} from "../../../App";
import {MC_Organization} from "../../iot/MC_Organization";
import OrganizationItem from "../item-list/OrganizationItem";
import MCLoadingBar from "../misc/MCLoadingBar";
import React from "react";
import {MC_Constants} from "../../MC_Constants";
import {CurrencyExchange} from "@mui/icons-material";
import DeviceItem from "../item-list/DeviceItem";
import {MC_Backend} from "../../MC_Backend";

interface SubscriptionTileInterface {
    sub: SimpleSubscription;
}
function SubscriptionTile({sub}: SubscriptionTileInterface) {
    // Constants
    const UNSUPPORTED_SUB_STATES: SimpleSubscriptionStatus[] = [
        "incomplete_expired",
        "paused",
        "canceled"
    ];
    // Load orgs if needed
    let mcOrgsCtx = React.useContext(OrganizationsContext);
    React.useEffect(() => {
        if (mcOrgsCtx.organizations == null && !mcOrgsCtx.loading && mcOrgsCtx.errMsg == null) {
            MC_Backend.getInstance().loadOrganizations().finally();
        }
    }, [mcOrgsCtx]);
    // Load devices if needed
    let mcDevicesCtx = React.useContext(DevicesContext);
    React.useEffect(() => {
       if (mcDevicesCtx.devices == null && !mcDevicesCtx.loading && mcDevicesCtx.errMsg == null) {
           MC_Backend.getInstance().loadDevices().finally();
       }
    }, [mcDevicesCtx]);
    // Parse data
    const status: SimpleSubscriptionStatus = sub.status;
    const isSubManual: boolean = (sub.collectionMethod === "send_invoice");
    const latestInvoice: SimpleInvoice | null = sub.latestInvoice;
    const lpiStatus: SimplePaymentIntentStatus | null = (latestInvoice != null)
        ? latestInvoice.paymentIntentStatus
        : null
    ;
    const isShortTrial: boolean = (status === "trialing" && MC_Constants.isInvoiceFromShortTrial(latestInvoice));
    const subscriptionTitle: string = MC_Constants.getSubscriptionTitle(sub);
    let ussWarning: string | null = null; // USS = Unsupported Subscription State
    if (UNSUPPORTED_SUB_STATES.includes(status)) {
        ussWarning = "UNSUPPORTED SUBSCRIPTION STATE: " + status;
    }
    const subIsActive: boolean = (status === "trialing" || status === "active");
    const createdStr: string = MC_Constants.getReadableDateStr(new Date(sub.created * 1000));
    const periodEndStr: string = MC_Constants.getReadableDateStr(new Date(sub.currentPeriodEnd * 1000));
    const cancelRenewStr: string = (sub.cancelAtPeriodEnd)
        ? "Cancels on " + periodEndStr
        : "Renews on " + periodEndStr
    ;
    /* Auto sub payment method name *IGNORED for now*
    let pmName: string | null = null; //
    if (isSubManual) {
        // IGNORE THIS FOR NOW
        // To get the name, we need to subscribe to the org context,
        // and then parse the found payment method name etc.
        // For now, let's leave everything pm related to the customer portal.
        pmName = null;
    }*/
    // Optionally create note for specific manual-only conditions
    let optionalManualNote: string | null = null;
    if (isSubManual) {
        // Manual conditions to look for:
        // 0) Free trial
        if (status === "trialing" && !isShortTrial) {
            optionalManualNote = "The free trial will end on " + periodEndStr + ".\n"
                + "After the trial ends, the customer will receive an email "
                + "with a link to pay the first invoice."
            ;
        }
        // 1) "Short" trial OR "draft" invoice
        if (isShortTrial || (latestInvoice != null && latestInvoice.status === "draft")) {
            optionalManualNote = "In a few minutes, the customer will receive an email "
                + "with a link to pay the first invoice."
            ;
        }
        // 2) Payment is due soon
        // latestInvoice is always non-null when the sub status is "active"
        if (status === "active" && (latestInvoice!.status === "open" && lpiStatus === "requires_payment_method")) {
            const amtRemainingStr: string = MC_Constants.getUSDStr(latestInvoice!.amountRemaining, true);
            const invoiceDueUnix: number = (latestInvoice!.dueDate != null) ? latestInvoice!.dueDate : 0;
            const invoiceDueDate: Date = new Date(invoiceDueUnix * 1000);
            const invDueStr: string = MC_Constants.getReadableDateStr(invoiceDueDate);
            optionalManualNote = "An invoice totaling " + amtRemainingStr
                + " is due by " + invDueStr + "."
            ;
        }
    }
    // Optionally create note for specific automatic-only conditions
    let optionalAutoNote: string | null = null;
    if (!isSubManual) {
        // Auto conditions to look for:

        // 0) Free trial
        if (status === "trialing" && !isShortTrial) {
            optionalAutoNote = "The free trial will end on " + periodEndStr + ".\n"
                + "After the trial ends, the customer's payment method "
                + "will be charged automatically."
            ;
        }
        // 1) "Short" trial
        if (isShortTrial) {
            optionalAutoNote = "In the next hour, the customer's payment method " +
                "will be used to pay the first invoice."
            ;
        }
        // 2) Payment coming soon (renewal payments take one hour)
        if (latestInvoice != null && lpiStatus != null) {
            const invStatus: SimpleInvoiceStatus | null = latestInvoice.status;
            if (invStatus === "draft" || (invStatus === "open" && lpiStatus === "requires_confirmation")) {
                const amtRemainingStr: string = MC_Constants.getUSDStr(latestInvoice.amountRemaining, true);
                optionalAutoNote = "The customer's payment method above will be charged "
                    + amtRemainingStr + " within one hour."
                ;
            }
        }
    }
    // Optionally create note for specific (auto or manual) subscription conditions
    let optionalSubInfoUI: JSX.Element | null = null;
    // Check for the following conditions:
    // 0) Invoice paid
    if (status !== "trialing" && (latestInvoice != null && latestInvoice.status === "paid")) {
        const paidStr: string = MC_Constants.getUSDStr(latestInvoice.amountPaid, true);
        const invCreatedDate: Date = new Date(latestInvoice.created * 1000);
        const invCreatedStr: string = MC_Constants.getReadableDateStr(invCreatedDate);
        optionalSubInfoUI =
            <span className={"note"}>
                The customer paid {paidStr} for the invoice created at {invCreatedStr}.
                <br/>
                No further payment is due at this time.
            </span>
        ;
    }
    // 1) Invoice payment processing
    if (latestInvoice != null && latestInvoice.status !== "paid" && lpiStatus === "processing") {
        const amtRemainingStr: string = MC_Constants.getUSDStr(latestInvoice.amountRemaining, true);
        optionalSubInfoUI =
            <span className={"note"}>
                The customer's payment is processing for an invoice totaling {amtRemainingStr}.
                <br/>
                They'll be notified when the payment processing is complete.
            </span>
        ;
    }
    // 2) Invoice payment requires action
    if (latestInvoice != null && latestInvoice.status !== "paid" && lpiStatus === "requires_action") {
        const amtRemainingStr: string = MC_Constants.getUSDStr(latestInvoice.amountRemaining, true);
        optionalSubInfoUI =
            <span className={"note"}>
                ACTION REQUIRED: The customer made a payment for an invoice totaling {amtRemainingStr}.
                <br/>
                Their billing email should've received instructions on how to complete this payment.
                <br/>
                Contact JAS if the customer didn't receive the instructions.
            </span>
        ;
    }
    // 3) Invoice overdue
    if ((status === "past_due" || status === "unpaid") && lpiStatus === "requires_payment_method") {
        // lpiStatus is non-null, so latestInvoice must be non-null
        const amtRemainingStr: string = MC_Constants.getUSDStr(latestInvoice!.amountRemaining, true);
        const dueUnix: number = (isSubManual)
            ? latestInvoice!.dueDate!
            : latestInvoice!.created
        ;
        const dueDate: Date = new Date(dueUnix * 1000);
        const dueStr: string = MC_Constants.getReadableDateStr(dueDate);
        optionalSubInfoUI =
            <span className={"error-note"}>
                The customer has an overdue invoice totaling {amtRemainingStr}, which was due on {dueStr}.
            </span>
        ;
    }
    // Link to view invoice online
    const viewInvoiceURL: string | null = (latestInvoice != null) ? latestInvoice.hostedInvoiceURL : null;
    // Link to download invoice
    const downloadInvoiceURL: string | null = (latestInvoice != null) ? latestInvoice.invoicePDFURL : null;
    // Parse IDs for display
    const orgID: string | null = MC_Constants.parseOrgIDFromSubscription(sub);
    const deviceIDs: string[] = MC_Constants.parseDeviceIDsFromSubscription(sub);
    // UI
    return (
        <Paper elevation={3} className={"content-start"}>
            <div className={"sub-tile-root"}>

                {/* Header row */}
                <div className={"sub-header-row"}>

                    {/* Icon col */}
                    <div className={"sub-header-icon-col"}>
                        <CurrencyExchange sx={{color:(subIsActive)?"green":"red"}} fontSize={"large"} />
                    </div>

                    {/* Name col */}
                    <div className={"sub-header-name-col"}>
                        <span className={"sub-title"}>{subscriptionTitle}</span>
                        <span className={"sub-subtitle"}>Created on {createdStr}</span>
                        <span className={"sub-subtitle"}>{cancelRenewStr}</span>
                    </div>

                </div>

                <Divider className={"divider"}/>

                {/* Invoice row
                <div className={"sub-data-row"}>
                    <span className={"note"}>Last Invoice: {invoicePaidStr} on {invoiceDateStr}</span>

                    <Divider/>
                </div>*/}

                {/* Last invoice details */}
                <div className={"sub-data-row"}>

                    {/* Regardless of collection_method, some states should be impossible */}
                    {(ussWarning != null) && <span className={"error-note"}>{ussWarning}</span>}

                    {/* (Automatic only) Default Payment method
                    {(pmName != null) && <span className={"note"}>Payment Method: {pmName}</span>}
                    */}

                    {/* Optional note specific to manual sub */}
                    {(optionalManualNote != null) && <span className={"note"}>{optionalManualNote}</span>}

                    {/* Optional note specific to auto sub */}
                    {(optionalAutoNote != null) && <span className={"note"}>{optionalAutoNote}</span>}

                    {/* Optional UI for any sub */}
                    {(optionalSubInfoUI != null) && optionalSubInfoUI}

                    {/* If possible, link to view invoice online */}
                    {(viewInvoiceURL != null) &&
                        <span className={"invoice-pdf-link"}>
                            <a href={viewInvoiceURL} target="_blank" rel="noopener noreferrer">
                                View Latest Invoice Online
                            </a>
                        </span>
                    }

                    {/* If there is no view link, show download link if
                    {(viewInvoiceURL == null && downloadInvoiceURL != null) &&
                        <span className={"invoice-pdf-link"}>
                            <a href={downloadInvoiceURL} target="_blank" rel="noopener noreferrer">
                                Download Latest Invoice
                            </a>
                        </span>
                    }*/}

                </div>

                <Divider className={"divider"}/>

                {/* Owner row */}
                <div className={"sub-data-row"}>
                    <span className={"note"}>Owned by: </span>
                    <OrganizationsContext.Consumer>
                        {(orgsCtx) => {
                            let foundOrg: MC_Organization | null = null;
                            if (orgsCtx.organizations != null) {
                                for (let org of orgsCtx.organizations) {
                                    if (orgID != null && org.id === orgID) {
                                        foundOrg = org;
                                    }
                                }
                            }
                            return (<div className={"sub-org-div"}>
                                {/* Loading */}
                                <MCLoadingBar
                                    loadingMessage={"Loading Organization..."}
                                    loading={orgsCtx.loading}
                                    errorMessage={orgsCtx.errMsg}
                                />
                                {/* Org */}
                                {(foundOrg != null) &&
                                    <OrganizationItem nameLink={true} org={foundOrg}/>
                                }
                                {/* Missing Org */}
                                {(foundOrg == null && orgsCtx.organizations != null) &&
                                    <span className={"error-note"}>Could not find org: {orgID}</span>
                                }
                            </div>);
                        }}
                    </OrganizationsContext.Consumer>
                    <Divider/>
                </div>

                <Divider className={"divider"}/>

                {/* Devices row */}
                <div className={"sub-data-row"}>
                    <span className={"note"}>Devices Included: </span>
                    <DevicesContext.Consumer>
                        {(devicesCtx) => {
                            let foundDevices: MC_Device[] = [];
                            let missingIDs: string[] = [];
                            if (devicesCtx.devices != null) {
                                // Parse devices
                                foundDevices = devicesCtx.devices.filter((d) => deviceIDs.includes(d.id));
                                const foundIDs: string[] = foundDevices.map((d) => d.id);
                                // Determine if any are missing
                                missingIDs = deviceIDs.filter((id) => !foundIDs.includes(id));
                            }
                            return (<div className={"sub-devices-div"}>
                                {/* Loading devices */}
                                <MCLoadingBar
                                    loading={devicesCtx.loading}
                                    loadingMessage={"Loading Devices..."}
                                    errorMessage={devicesCtx.errMsg}
                                />
                                {/* Devices */}
                                {foundDevices.map((d) => <DeviceItem key={d.id} nameLink={true} device={d}/>)}
                                {/* Missing devices */}
                                {(missingIDs.length > 0) &&
                                    <div>
                                        <p className={"error-note"}>The following devices could not be found: </p>
                                        {missingIDs.map((id) => <p className={"note"}>{id}</p>)}
                                    </div>
                                }
                                {/* No devices */}
                                {(deviceIDs.length === 0) &&
                                    <p className={"error-note"}>No devices associated with this subscription.</p>
                                }
                            </div>);
                        }}
                    </DevicesContext.Consumer>
                </div>
            </div>
        </Paper>
    );
}

export default SubscriptionTile;