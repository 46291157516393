import "./AdminCore.css";
import MenuIcon from '@mui/icons-material/Menu';
import SpeedIcon from '@mui/icons-material/Speed';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessIcon from "@mui/icons-material/Business";
import {
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    Divider,
    ListItem,
    List,
    ListItemButton, ListItemIcon, ListItemText, CssBaseline, useTheme, Badge
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {CustomConstants} from "../custom/CustomConstants";
import React, {useContext, useEffect} from "react";
import {Outlet} from "react-router-dom";
import {Devices, Router, Wysiwyg} from "@mui/icons-material";
import {MC_Backend} from "../common/MC_Backend";
import whiteLogo from "../white_logo_pa.png";
import {styled} from "@mui/material/styles";
import MuiAppBar from '@mui/material/AppBar';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {IssuesContext} from "../App";

const mobileCutoff = 700; // If screen width is less than this, drawer should sit above
const drawerWidth = 220;

const MCMain = styled('main', {
    shouldForwardProp: (prop) => (prop !== 'open' && prop !== "mobile")
})<{
    open?: boolean;
    mobile?: boolean;
}>(({ theme, open, mobile }) => ({

    flexGrow: 1,
    padding: theme.spacing(3),

    // Closed
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: "0px",

    // Open
    ...((open && !mobile) && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${drawerWidth}px`,
    })
}));

interface JASAppBarProps { // extends app bar props
    open?: boolean;
    mobile?: boolean;
}

const MCAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => (prop !== 'open' && prop !== "mobile")
})<JASAppBarProps>(({ theme, open, mobile }) => ({

    // Closed
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

    // Open
    ...((open && !mobile) && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    alignContent: 'flex-end',
}));

function AdminCore(props: any) {
    const theme = useTheme();
    const navigate = useNavigate();
    const loc = useLocation();

    // Reroute to log in if not logged in
    useEffect(() => {
        // Regardless of location.pathname, the user must be authorized to be here
        if (!MC_Backend.getInstance().isSignedIn()) {
            console.log("User not signed in, rerouting...");
            navigate("/log_in"); // Back to log in
        }
    }, [navigate]);

    // Listen for window resize
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    React.useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    const useMobileDrawer: boolean = (width <= mobileCutoff);

    // Determine number of unresolved support badges issues
    const ctx = useContext(IssuesContext);
    let supportProblems: number = 0;
    if (ctx.issues != null) {
        supportProblems = ctx.issues.filter((x) => x.open && x.help_uids.length === 0).length;
    }

    // State
    const [open, setOpen] = React.useState(true);
    //const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);
    const toggleDrawer = () => setOpen(!open);

    // Helper fns
    const logOutFn = () => {
        MC_Backend.getInstance().signOut().finally(() => {
            navigate("/log_in");
        });
    };
    const backdropClickFn = (event: object, reason: string) => {
        if (reason === "backdropClick") {
            handleDrawerClose();
        }
    };

    // Return sx based on the button & associated path
    const menuBtnSxFn = (path: string) => {
        const isActive: boolean = loc.pathname.startsWith("/core/" + path);
        return {
            // Background
            ...(isActive && {backgroundColor: "white" }),
            "&:hover": {
                backgroundColor: (isActive) ? "white" : theme.palette.primary.dark,
            },
            // Text
            "& .menu-btn-text": {
                color: (isActive) ? theme.palette.primary.main : "white",
                "&:hover": {
                    //textDecoration: "underline"
                }
            },
            // Icon
            "& .menu-btn-icon": {
                color: (isActive) ? theme.palette.primary.main : "white",
            }
        };
    };

    // Return UI
    return (
        <div className={"admincore-root"} key={"corekey"}>

            <CssBaseline/>

            {/* App bar */}
            {/* Static instead of fixed?*/}
            <MCAppBar position="fixed" open={open} mobile={useMobileDrawer}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{ mr: 2, justifySelf: "flex-start" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" noWrap component="div">
                        Admin Portal
                    </Typography>
                </Toolbar>
            </MCAppBar>

            {/* Drawer side menu */}
            <Drawer
                ModalProps={{
                    onClose: backdropClickFn
                }}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        backgroundColor: "primary.main",
                        boxSizing: 'border-box',
                    },
                }}
                variant={useMobileDrawer ? "temporary" : "persistent"}
                anchor="left"
                open={open}
            >

                <DrawerHeader>
                    {/* white Logo */}
                    <img src={whiteLogo} className={"menu-logo"} alt={"logo"} />
                </DrawerHeader>

                <Divider />

                <List className={"ac-list"}>
                    {/* Overview */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("overview")} onClick={() => navigate("/core/overview")}>
                            <ListItemIcon>
                                <SpeedIcon className={"menu-btn-icon"} />
                            </ListItemIcon>
                            <ListItemText primary={"Overview"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>
                </List>

                {/* Divider to make overview distinct? */}
                <Divider />

                {/* Core menu items */}
                <List className={"ac-list"}>
                    {/* Devices */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("devices")} onClick={() => navigate("/core/devices")}>
                            <ListItemIcon>
                                <Router className={"menu-btn-icon"} />
                            </ListItemIcon>
                            <ListItemText primary={"Monitors"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>
                    {/* Organizations */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("organizations")} onClick={() => navigate("/core/organizations")}>
                            <ListItemIcon>
                                <BusinessIcon className={"menu-btn-icon"} />
                            </ListItemIcon>
                            <ListItemText primary={"Organizations"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>
                    {/* Users */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("users")} onClick={() => navigate("/core/users")}>
                            <ListItemIcon>
                                <GroupsIcon className={"menu-btn-icon"} />
                            </ListItemIcon>
                            <ListItemText primary={"Users"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>
                    {/* Recent Alerts */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("alerts")} onClick={() => navigate("/core/alerts")}>
                            <ListItemIcon>
                                <NotificationsActiveIcon className={"menu-btn-icon"} />
                            </ListItemIcon>
                            <ListItemText primary={"Recent Alerts"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>
                    {/* Support */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("support")} onClick={() => navigate("/core/support")}>
                            <ListItemIcon>
                                <Badge color={"error"} badgeContent={supportProblems} invisible={supportProblems === 0}>
                                    <ContactSupportIcon className={"menu-btn-icon"} />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={"Support"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>
                    {/* Logs */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("logs")} onClick={() => navigate("/core/logs")}>
                            <ListItemIcon>
                                <Wysiwyg className={"menu-btn-icon"} />
                            </ListItemIcon>
                            <ListItemText primary={"Logs"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>
                    {/* Settings */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("settings")} onClick={() => navigate("/core/settings")}>
                            <ListItemIcon>
                                <SettingsIcon className={"menu-btn-icon"} />
                            </ListItemIcon>
                            <ListItemText primary={"Settings"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>
                </List>

                <Divider/>

                {/* Logout */}
                <List className={"ac-list"}>
                    {/* Log Out */}
                    <ListItem disablePadding>
                        <ListItemButton sx={menuBtnSxFn("logout")} onClick={logOutFn}>
                            <ListItemIcon>
                                <LogoutIcon className={"menu-btn-icon"} />
                            </ListItemIcon>
                            <ListItemText primary={"Log Out"} className={"menu-btn-text"} />
                        </ListItemButton>
                    </ListItem>

                </List>

            </Drawer>

            {/* Navigation drawer for child content */}
            <MCMain open={open} mobile={useMobileDrawer}>

                {/* So content isn't hidden by appbar */}
                <DrawerHeader/>

                {/* Router Outlet for nested pages */}
                <Outlet/>

            </MCMain>

        </div>
    );
}

export default AdminCore;
