
export class CustomConstants {

    // Version
    public static ADMIN_SOFTWARE_VERSION: string = "v0.3.4";

    // Admin
    public static ADMIN_SOFTWARE_NAME: string = "PureAire CloudConnect";
    public static ADMIN_SOFTWARE_ENDPOINT: string = "https://admin.app.pureairemonitoring.com";
    public static MONITORING_SOFTWARE_URL: string = "https://app.pureairemonitoring.com";

    // Backend
    public static API_ENDPOINT: string = "https://us-central1-jas-pureaire.cloudfunctions.net/v2";
    public static SERVER_UIDS: string[] = [
        "jas-data-server",
        "jas-schedule-server",
        "jas-billing-server",
        "jot-server"
    ];

    // (Automatic billing)
    public static STRIPE_OEM_CONNECT_ID: string = "acct_1NMIoRGi5I5Tebc9";
    public static STRIPE_PRICE_GAS_CELL: string = "price_1OMwtvGi5I5Tebc9nD64AnHN";
    public static STRIPE_PRICE_GAS_WIFI: string = "price_1OMwtBGi5I5Tebc9sHFe3hVN";

    // Return the stripe fee amount for a given price ID (100 = $1)
    public static getJASFeeForStripePriceID(priceID: string | null): number {
        if (priceID == null) {
            return 0;
        } else if (priceID === this.STRIPE_PRICE_GAS_CELL) {
            // $445 total
            // $189 JAS fee
            return 189 * 100;
        } else if (priceID === this.STRIPE_PRICE_GAS_WIFI) {
            // $395 total
            // $141 JAS fee
            return 141 * 100;
        }
        // Default to none
        return 0;
    }

}
