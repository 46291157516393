import "./DeviceInfoTab.css";
import {Alert, Button, Divider, Paper, Snackbar, TextareaAutosize} from "@mui/material";
import DetailRow from "../../../common/ui/DetailRow";
import React, {PropsWithChildren} from "react";
import {MC_Device} from "../../../common/iot/MC_Device";
import TextInputRow from "../../../common/ui/misc/TextInputRow";
import {MC_Backend} from "../../../common/MC_Backend";
import {MC_Constants} from "../../../common/MC_Constants";
import {MC_UI_Helper} from "../../../common/iot/MC_UI_Helper";
import {CustomConstants} from "../../../custom/CustomConstants";

export interface DeviceInfoTabProps {
    device: MC_Device;
    refreshTS: Date;
    setDeleteDeviceOpen: (x: boolean) => void;
}
function DeviceInfoTab(props: PropsWithChildren<DeviceInfoTabProps>) {
    // Constants
    const device = props.device;
    // State
    const [progressSnackOpen, setProgressSnackOpen] = React.useState<boolean>(false);
    const [errorSnackOpen, setErrorSnackOpen] = React.useState<boolean>(false);
    const [successSnackOpen, setSuccessSnackOpen] = React.useState<boolean>(false);
    const [tempName, setTempName] = React.useState<string>(device.name);
    const [tempAdminNotes, setTempAdminNotes] = React.useState<string>(device.adminNotes);
    const [opInProgress, setOpInProgress] = React.useState<boolean>(false);
    // Help
    const isOnline = device.isOnline(props.refreshTS);
    const updateFn = () => {
        if (!opInProgress) {
            if (tempName === "") {
                setErrorSnackOpen(true);
                return;
            }
            setOpInProgress(true);
            setProgressSnackOpen(true);
            MC_Backend.getInstance().updateDeviceNameAndAdminNotes(device.id, tempName, tempAdminNotes)
                .then(() => {
                    setSuccessSnackOpen(true);
                })
                .catch((e) => {
                    console.error("Update err: " + JSON.stringify(e));
                    setErrorSnackOpen(true);
                })
                .finally(() => {
                    setOpInProgress(false);
                })
            ;
        }
    };
    const setSoldFn = (newSold: boolean) => {
        if (!opInProgress) {
            setOpInProgress(true);
            setProgressSnackOpen(true);
            MC_Backend.getInstance().updateDeviceSold(device.id, newSold)
                .then(() => {
                    setSuccessSnackOpen(true);
                })
                .catch((e) => {
                    console.error("Update err: " + JSON.stringify(e));
                    setErrorSnackOpen(true);
                })
                .finally(() => {
                    setOpInProgress(false);
                })
            ;
        }
    };
    // UI
    return (
        <div className={"dinfotab-root"}>

            {/* Snack bars */}
            <Snackbar
                open={progressSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setProgressSnackOpen(false)}>
                <Alert severity="info" sx={{ width: '100%' }}>
                    Updating Monitor...
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setErrorSnackOpen(false)}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Error Updating Monitor.
                </Alert>
            </Snackbar>
            <Snackbar
                open={successSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setSuccessSnackOpen(false)}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Updated Monitor.
                </Alert>
            </Snackbar>

            {/* Basic info */}
            <Paper className={"content-start"} elevation={3}>
                <TextInputRow label={"Customer Given Name"} value={tempName}
                              updateValue={(newValue) => setTempName(newValue)}
                />
                <br/>
                <DetailRow label={"Type"} value={device.fullType}/>
                <DetailRow label={"Serial Number"} value={device.jas_id}/>
                <DetailRow label={"Claim Code"} value={device.claim_code}/>
                <DetailRow label={"Status"}
                           value={(isOnline) ? "ONLINE" : "OFFLINE"}
                           color={(isOnline) ? "good" : "bad"}
                />
                <DetailRow label={"Sold State"}
                           value={(device.sold) ? "Sold" : "Unsold"}
                           color={(device.sold) ? "good" : "bad"}
                />
                <DetailRow label={"License Status"} valueJSX={MC_UI_Helper.getLicenseCell(device)}/>
                <DetailRow label={"Last Seen"} value={MC_Constants.getReadableDateStr(device.last_post)}/>
                <br/>
                <Divider className={"divider"} />
                <br/>
                <DetailRow label={"Customer License Pricing"}
                           value={MC_Constants.getUSDStr(device.annualPrice) + "/year"}
                           color={"good"}
                />
                <DetailRow label={"JAS Fee Pricing"}
                           value={MC_Constants.getUSDStr(CustomConstants.getJASFeeForStripePriceID(device.priceID)) + "/year"}
                           color={"orange"}
                />
                <span className={"jas-fee-note"}>JAS Fees are deducted automatically upon customer payment.</span>
                <br/>
                <Divider className={"divider"} />
                <br/>
                <DetailRow label={"ID"} value={device.id}/>
                <DetailRow label={"Created"} value={MC_Constants.getReadableDateStr(device.created)}/>
                <DetailRow label={"Modified"} value={MC_Constants.getReadableDateStr(device.modified)}/>
                <br/>
                <Divider className={"divider"} />
                <br/>
                <DetailRow label={"User Notes"} value={""}/>
                <TextareaAutosize
                    className={"notes-area"}
                    minRows={4} maxRows={8} maxLength={140}
                    disabled={true}
                    placeholder={"There are no user notes about this monitor."}
                    value={device.notes}
                />
                <DetailRow label={"Admin Notes"} value={""}/>
                <TextareaAutosize
                    className={"notes-area"}
                    minRows={4} maxRows={8} maxLength={140}
                    disabled={false}
                    placeholder={"Notes about this monitor are private to management, and are NOT visible to users."}
                    value={tempAdminNotes}
                    onChange={(ev) => setTempAdminNotes(ev.target.value)}
                />
                <br/>
                <div className={"center-row"}>
                    <Button variant="contained" color={"primary"}
                            disabled={opInProgress} onClick={updateFn}>
                        Update Name & Notes
                    </Button>
                </div>
            </Paper>
            <br/>
            {/* Optional settings content
            THIS WAS MOVED TO ITS OWN TAB
            {
                (props.children != null) &&
                <React.Fragment>
                    {props.children}
                    <br/>
                </React.Fragment>
            }*/}
            {/* Mark the device sold/unsold */}
            <div>
                <p className={"note btn-note"}>Mark the device as "Sold" or "Unsold".</p>
                <Button variant="contained" color={(device.sold) ? "secondary" : "primary"}
                        onClick={() => setSoldFn(!device.sold)}>
                    <strong>Set Device as {(device.sold) ? "Unsold" : "Sold"}</strong>
                </Button>
            </div>
            {/* Delete the device */}
            <div>
                <p className={"note btn-note"}>This will permanently delete the monitor, and remove it from all organizations.</p>
                <Button variant="contained" color={"warning"} onClick={() => props.setDeleteDeviceOpen(true)}><strong>Delete Monitor</strong></Button>
            </div>
        </div>
    );
}

export default DeviceInfoTab;